/* ready */
$(function () {
	'use strict';

	// lnavi
	naviCurrent($('.p-page__lnavi ul li'));

	function naviCurrent(naviElm) {
		const currentUrl = location.pathname + location.hash;

		naviElm.each(function () {
			let link = $(this).children('a').attr('href');
			var matchLink = new RegExp('^' + link);

			if (link == currentUrl || currentUrl.match(matchLink)) {
				$(this).addClass('is-current');
			}
		});
	}
});