/* ready */
$(function () {
  'use strict';

  /* スムーススクロール */
  $('a[href^="#"]').on('click', function () {
    // スピード（ミリ秒）
    var speed = 300;
    // アンカーの値取得
    var href = $(this).attr('href');
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を座標で取得
    var position;
    if ($(this).parent().hasClass('l-pagetop')) {
      position = target.offset().top;
    } else {
      position = target.offset().top - $('.l-header').outerHeight();
    }
    // アニメーション
    $('body,html').not(':animated').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });

  /* メガメニュー  */
  $('.l-menu__btn').on('click', function () {
    $(this).toggleClass('is-open');
    $('.l-mega').stop().fadeToggle();
  });

  $('.l-mega__main li:first-child > span + span').on('click', function () {
    $('.l-menu__btn').removeClass('is-open');
    $('.l-mega').fadeOut();
  });

  // タブ切り替え
  $('.l-mega__main > li > span:first-child').on('click', function () {
    let index = $(this).parent().index('.l-mega__main > li');

    $(this).parent().addClass('is-select').siblings().removeClass('is-select');
    $('.l-mega__sub > div').hide();
    $('.l-mega__sub > div').eq(index).show();
  });

  // アコーディオン
  $('.l-mega__nav__list > li > a + span, .l-mega__nav__list > li > span + span').on('click', function () {
    $(this).parents('li').toggleClass('is-open').children('ul').stop().slideToggle();
  });

  // ページトップ
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 80) {
      $('.l-pagetop').stop(true, false).fadeIn();
    } else {
      $('.l-pagetop').stop(true, false).fadeOut();
    }
  });
});