/* ready */
$(function () {
  'use strict';

  // MV
  let video = $('.p-top-mv > video');
  let status = video.get(0);
  // let cookie_save = $.cookie('access');

  // if (cookie_save !== 'first') {
  //   // $.cookie('access', 'first');
    video.show();
    status.addEventListener('ended', function () {
      video.delay(500).fadeOut(500).parent().addClass('is-finished');
    });
  // } else {
  //   video.parent().addClass('is-finished');
  // }

  // MVリンク
  $('.p-top-mv__link__main > li').on('mouseenter', function () {
    let index = $('.p-top-mv__link__main > li').index(this);

    $(this).addClass('is-select').siblings().removeClass('is-select');
    $('.p-top-mv__link__sub > ul').removeClass('is-select');
    $('.p-top-mv__link__sub > ul').eq(index).addClass('is-select');
  });
});